<template>
    <div class="container mt-5 text-justify">
        <div class="col-12 col-md-12 col-lg-12">
            <b>Tipos de Locação</b>

            <p class="m-0">• Diária com Km livre </p>

            <p class="m-0">• Faturado </p>

            <p class="m-0">• Reserva facilitada </p>

            <p class="m-0">• Aluguel de acordo com a real necessidade da sua empresa </p>

            <p class="m-0">• Diária com 25h </p>

            <p class="m-0">• Melhor custo-benefício, com tarifa e condições especiais </p>

            <p class="m-0">• Tarifa fixa independente da sazonalidade </p>

            <p class="m-0">• Velocidade na retirada e na devolução do veículo </p>

            <p class="">• Oferta exclusiva para sua empresa </p>

            <p>Entre em contato com nosso time comercial. Envie os dados da sua empresa para que seja realizado o cadastro e
                avaliação cadastral. Em seguida enviaremos uma proposta de tarifa.</p>

            <p>Após aprovação de proposta, basta enviar um e-mail para King solicitando o veículo.</p>
        </div>
    </div>
</template>

<script>

export default {
    name: "Rac",
    data: () => ({}),
    created(){
        document.title = 'King Rent a Car - RAC'
    },
}
</script>