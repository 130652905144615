<template>
    <div class="container my-5">
        <div class="row mb-5">
            <NavbarProgressoReserva :progresso="progresso"></NavbarProgressoReserva>
        </div>

        <!-- PROGRESSO 1 -->
        <div v-if="!progresso.etapa1 && !getCarregando" class="row">
            <h6 class="text-muted mb-3">Selecione o tipo de veiculo</h6>
            <div class="col-md-6 col-lg-4 mb-3" v-for="(car, index) in cars" :key="car.id">
                <div class="card card-carro">
                    <div class="card-body">
                        <div class="row d-flex justify-content-center">
                            <div class="col-12">
                                <div class="text-center">
                                    <h5 class="text-uppercase mb-3">Grupo {{ car.titulo }} - {{ car.categoria }}</h5>
                                    <h6 class="text-center"> {{ car.modelo }} </h6>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center" v-if="!car.informacoes.displayInfo">
                                <div class="col-12 d-flex justify-content-center my-sm-3">
                                    <div class="animate__animated"
                                        :class="!car.informacoes.displayInfo ? 'animate__flipInY' : ''">
                                        <img :src="`https://www.kingcar.com.br/uploads/tarifario/${car.imagem}`"
                                            class="img-fluid-carro" />
                                    </div>
                                </div>
                                <p class="text-muted small text-center">* Sujeito a disponibilidade da agência</p>

                                <!-- CARD DE PREÇO DE DIÁRIA DE ATÉ 5 DIAS -->
                                <div v-if="getFormularioReserva.diarias <= 5" class="col-12 bg-grey rounded text-center">
                                    <h4 class="my-3">
                                        <span class=" px-4 position-relative">
                                            {{ formatarValor(car.diaria5dias) }}
                                            <span class="position-absolute top-10 start-90 small-2">
                                                **
                                            </span>
                                        </span>
                                    </h4>
                                    <p class="text-muted small text-center">** Demais taxas não inclusas nesse valor</p>
                                </div>

                                <!-- CARD DE PREÇO DE DIÁRIA MAIOR OU IGUAL A 6 DIAS -->
                                <div v-if="getFormularioReserva.diarias >= 6" class="col-12 bg-grey rounded text-center">
                                    <small>de</small>
                                    <h6 class="text-decoration-line-through">{{ formatarValor(car.diaria5dias) }}</h6>
                                    <small class="">por</small>
                                    <h4 class="mt-2 mb-3">
                                        <span class="bg-primary rounded-pill px-4 position-relative">
                                            {{ formatarValor(car.diaria6dias) }}
                                            <span class="position-absolute top-10 start-90 small-2">
                                                **
                                            </span>
                                        </span>
                                    </h4>
                                    <p class="text-muted small text-center">** Demais taxas não inclusas nesse valor</p>
                                </div>
                            </div>

                            <div class="row animate__animated" v-else>
                                <div class="col-12">
                                    <div class="row my-sm-3 animate__animated"
                                        :class="car.informacoes.displayInfo ? 'animate__flipInY' : ''">
                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">

                                                <i class="bi bi-gear-wide-connected me-2 text-primary"></i>
                                                <span>{{ car.informacoes.motor }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-pin-map-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.automatico == 1 ? 'Automático' : 'Manual' }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">

                                                <i class="bi bi-door-open-fill me-2 text-primary"></i>
                                                <span>{{ car.informacoes.portas }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-people-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.pessoas }}</span>
                                            </div>
                                        </div>

                                        <div class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">

                                                <i class="small bi bi-briefcase-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.malas }}</span>
                                            </div>
                                        </div>

                                        <div v-if="car.informacoes.fuel" class="col-6 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-fuel-pump-fill text-primary me-2"></i>
                                                <span>{{ car.informacoes.fuel }}</span>
                                            </div>
                                        </div>

                                        <div v-if="car.informacoes.ar == 1" class="col-12 mb-2">
                                            <div class="bg-grey rounded p-2">
                                                <i class="small bi bi-snow text-primary me-2"></i>
                                                <span>Ar-Condicionado</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="cursor-pointer d-flex justify-content-between">
                            <p @click="() => exibirMaisDetalhes(index)" v-if="!car.informacoes.displayInfo"
                                class="text-primary cursor-pointer m-0">Mais detalhes</p>
                            <p @click="() => exibirMaisDetalhes(index)" v-else class="text-primary cursor-pointer m-0">Menos
                                detalhes</p>
                            <button @click="selecionarCarro(car.id)" type="button"
                                class="btn btn-primary">Selecionar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- PROGRESSO 2 -->
        <div v-if="progresso.etapa1 && !progresso.etapa2 && !getCarregando" class="row">
            <!-- PROTEÇÃO -->
            <div class="col-md-6 mb-4">
                <h6 class="text-muted mb-3">Selecione a proteção do veiculo*</h6>
                <div v-for="protecao in getProtectionsCategoria" :key="protecao.id" class="bg-grey p-3 my-sm-1 rounded">
                    <div class="form-check">
                        <input v-if="protecao.tipo == 1" @click="adicionarRemoverProtecao(protecao.id)"
                            class="form-check-input" type="checkbox" name="protecaoEstendida"
                            :checked="servicos.protecao.includes(protecao.id)" id="flexRadioDefault1">

                        <input v-if="protecao.tipo == 2" @click="adicionarRemoverProtecao(protecao.id, protecao.tipo)"
                            class="form-check-input" type="radio" name="tipoProtecao"
                            :checked="servicos.tipoProtecao.includes(protecao.id)">

                        <div class="form-check-label" for="flexRadioDefault1">
                            <p class="m-0">{{ protecao.titulo }} <span class="badge bg-primary ms-2">{{
                                formatarValor(protecao.valor) }}</span></p>
                            <small>{{ protecao.texto }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <!-- OPCIONAIS -->
            <div class="col-md-6 mb-4">
                <h6 class="text-muted mb-3">Serviços opcionais</h6>
                <div v-for="opcional in getOpcionais" :key="opcional.id" class="bg-grey p-3 my-sm-1 rounded">
                    <div class="form-check">
                        <input @click="adicionarRemoverOpcional(opcional.id)" class="form-check-input" type="checkbox"
                            name="flexRadioDefault" id="flexRadioDefault1"
                            :checked="servicos.opcionais.includes(opcional.id)">
                        <div class="form-check-label">
                            <p class="m-0">{{ opcional.titulo }}<span class="badge bg-primary ms-2">{{
                                formatarValor(opcional.valor) }}</span>
                            </p>
                            <small>{{ opcional.texto }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <p class="text-muted small">*Necessário escolher um tipo de proteção</p>

            <!-- AÇÕES -->
            <div class="col-12 d-flex justify-content-between">
                <button @click="() => progresso.etapa1 = false" type="button" class="btn btn-light me-3">Voltar</button>
                <button @click="selecionarServicos" type="button"
                    :class="servicos.tipoProtecao.length == 0 ? 'disabled' : ''" class="btn btn-primary">Próximo</button>
            </div>
            <div class="col-12 order-md-4 d-flex justify-content-end">
                <button @click="cancelarReserva" type="button" class="btn btn-light">Cancelar reserva</button>
            </div>
        </div>
        <!-- PROGRESSO 3 -->
        <div v-if="progresso.etapa2 && !progresso.etapa3 && !getCarregando" class="row">
            <h6 class="text-muted mb-3">Resumo da reserva</h6>

            <div class="col-md-6">
                <div class="row">
                    <!-- Retirada e Devolução -->
                    <div class="col-md-12 mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <!-- Retirada -->
                                    <div class="mb-4 col-12">
                                        <h6 class="text-uppercase small">
                                            Retirada
                                        </h6>
                                        <div class="d-flex justify-content-between ">
                                            <p class="text-primary m-0 d-flex">
                                                <i class="bi bi-calendar3 me-1"></i>
                                                {{ formatarData(getFormularioReserva.dataRetirada) }}
                                            </p>
                                            <p class="text-primary m-0">
                                                <i class="bi bi-geo-alt-fill me-1"></i>
                                                {{ getFormularioReserva.localRetirada }}
                                            </p>
                                        </div>
                                        <p class="text-primary mb-0 d-flex">
                                            <i class="bi bi-clock-fill me-1"></i>
                                            {{ getFormularioReserva.horarioRetirada }}
                                        </p>
                                    </div>
                                    <!-- Devolução -->
                                    <div class="col-12">
                                        <h6 class="text-uppercase small">
                                            Devolução
                                        </h6>
                                        <div class="d-flex justify-content-between ">
                                            <p class="text-primary m-0 d-flex">
                                                <i class="bi bi-calendar3 me-1"></i>
                                                {{ formatarData(getFormularioReserva.dataDevolucao) }}
                                            </p>
                                            <p class="text-primary m-0">
                                                <i class="bi bi-geo-alt-fill me-1"></i>
                                                {{ getFormularioReserva.localDevolucao }}
                                            </p>
                                        </div>
                                        <p class="text-primary mb-0 d-flex">
                                            <i class="bi bi-clock-fill me-1"></i>
                                            {{ getFormularioReserva.horarioDevolucao }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Veículo Grupo Tarifa -->
                    <div class="col-md-12 mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <!-- Veículo -->
                                    <div class="col-9">
                                        <h6 class="text-uppercase small">
                                            Veículo
                                        </h6>
                                        <p class="text-primary"><i class="bi bi-car-front-fill"></i>
                                            {{ getFormularioReserva.veiculo }}
                                        </p>
                                    </div>
                                    <!-- Grupo -->
                                    <div class="col-3">
                                        <h6 class="text-uppercase small">
                                            Grupo
                                        </h6>
                                        <p class="text-primary">
                                            {{ getFormularioReserva.grupo }}
                                        </p>
                                    </div>
                                    <!-- Tarifa -->
                                    <div class="col-12">
                                        <h6 class="text-uppercase small">
                                            Tarifa
                                        </h6>
                                        <p class="text-primary">
                                            {{ getFormularioReserva.tarifa }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col md-6">
                <div class="row">
                    <!-- Diária Proteção Opcional Hora Extra Taxa (10%) -->
                    <div class="col-md-12 mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <p class="text-muted small">Valores totais referente ao período de locação</p>
                                    <!-- Diária -->
                                    <div class="col-4 mb-4 col-md-6">
                                        <h6 class="text-uppercase small">
                                            Diária <i class="bi bi-exclamation-circle" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                                :data-bs-title="`Valor por dia ${formatarValor(getFormularioReserva.valorDiaria)}`"></i>
                                        </h6>
                                        <p class="text-primary m-0">
                                            {{ formatarValor(getFormularioReserva.valorTotalDiaria) }}
                                        </p>
                                    </div>
                                    <!-- Proteção-->
                                    <div class="col-4 mb-4 col-md-6">
                                        <h6 class="text-uppercase small">
                                            Proteção <i class="bi bi-exclamation-circle" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                                :data-bs-title="`Valor por dia ${formatarValor(getFormularioReserva.valorProtecao)}`"></i>
                                        </h6>
                                        <p class="text-primary m-0">
                                            {{ formatarValor(getFormularioReserva.valorTotalProtecao) }}
                                        </p>
                                    </div>
                                    <!-- Opcional-->
                                    <div class="col-4 mb-4 col-md-6">
                                        <h6 class="text-uppercase small">
                                            Opcional <i class="bi bi-exclamation-circle" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                                :data-bs-title="`Valor por dia ${formatarValor(getFormularioReserva.valorOpcional)}`"></i>
                                        </h6>
                                        <p class="text-primary m-0">
                                            {{ formatarValor(getFormularioReserva.valorTotalOpcional) }}
                                        </p>
                                    </div>
                                    <!-- Hora Extra-->
                                    <div class="col-4 mb-4 col-md-6">
                                        <h6 class="text-uppercase small">
                                            Hora Extra <i class="bi bi-exclamation-circle" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-custom-class="custom-tooltip"
                                                :data-bs-title="`Valor por hora excedida ${formatarValor(getFormularioReserva.valorHoraExtra)}`"></i>
                                        </h6>
                                        <p class="text-primary m-0">
                                            {{ formatarValor(getFormularioReserva.valorTotalHoraExtra) }}
                                        </p>
                                    </div>
                                    <!-- Taxa (10%)-->
                                    <div class="col-4 mb-4 col-md-6">
                                        <h6 class="text-uppercase small">
                                            Taxa (10%)
                                        </h6>
                                        <p class="text-primary m-0">
                                            {{ formatarValor(getFormularioReserva.taxa) }}
                                        </p>
                                    </div>
                                    <p v-if="getFormularioReserva.horaExtra > 0" class="text-muted small">Você excedeu {{
                                        getFormularioReserva.horaExtra + (getFormularioReserva.horaExtra > 1 ? ' horas' :
                                            'hora') }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Valor Final -->
            <div class="col-12 order-md-3 text-end mb-3">
                <h6 class="text-uppercase">
                    Valor final
                </h6>
                <h4 class="text-uppercase">
                    {{ formatarValor(getFormularioReserva.valorTotal) }}
                </h4>
            </div>

            <!-- AÇÕES -->
            <div class="col-12 order-md-4 d-flex justify-content-between">
                <button @click="() => progresso.etapa2 = false" type="button" class="btn btn-light me-3">Voltar</button>
                <button @click="confirmar" type="button" class="btn btn-primary">Próximo</button>
            </div>
            <div class="col-12 order-md-4 d-flex justify-content-end">
                <button @click="cancelarReserva" type="button" class="btn btn-light">Cancelar reserva</button>
            </div>

        </div>
        <!-- PROGRESSO 4 -->
        <div v-if="progresso.etapa3 && !progresso.etapa4 && !getCarregando" class="row">
            <h6 class="text-muted mb-3">Preencha o formulário e finalize sua solicitação</h6>
            <div class="col-12">

                <div class="row d-flex justify-content-center">

                    <div class="form-group col-md-6 mb-3">
                        <input v-model="formularioContato.nome" placeholder="Nome" type="text" class="form-control"
                            id="nome">
                    </div>

                    <div class="form-group col-md-6 mb-3">
                        <input v-model="formularioContato.email" placeholder="Email" type="text" class="form-control"
                            id="email">
                    </div>

                    <div class="form-group col-md-4 mb-3">
                        <input v-model="formularioContato.cnpjCpf" placeholder="CPF ou CNPJ" type="tel" class="form-control"
                            id="alugar" v-mask="['###.###.###-##', '##.###.###/####-##']">
                    </div>

                    <div class="form-group col-md-4 mb-3">
                        <input v-model="formularioContato.codigoPromocional" placeholder="Código promocional" type="text"
                            class="form-control" id="cidade">
                    </div>

                    <div class="form-group col-md-4 mb-3">
                        <input v-model="formularioContato.telefone" placeholder="telefone" type="tel" class="form-control"
                            id="contatoCliente" v-mask="['(##) ####-####', '(##) #####-####']">
                    </div>

                    <div class="form-group col-md-12 mb-3">
                        <textarea v-model="formularioContato.observacoes" placeholder="Observações" class="form-control"
                            id="mensagem" rows="4" style="height: auto;"></textarea>
                    </div>

                    <!-- reCAPTCHA -->
                    <div class="mb-3">
                        <Captcha ref="captcha"></Captcha>
                    </div>

                    <div class="form-group col-md-12 mb-5">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="formularioContato.aceitarTermos"
                                name="flexRadioDefault" id="flexRadioDefault1">
                            <div class="form-check-label" for="flexRadioDefault1">
                                <p class="m-0">Aceito os termos do contrato de locação</p>
                                <small><a class="link-underline-light text-primary"
                                        href="https://www.kingcar.com.br/pdf/Contrato-Revisado-Site-2019.pdf">Visualize
                                        aqui o
                                        contrato de locação</a></small>
                            </div>
                        </div>
                    </div>

                    <!-- AÇÕES -->
                    <div class="col-12 order-md-4 d-flex justify-content-between mb-3">
                        <button @click="() => progresso.etapa3 = false" type="button"
                            class="btn btn-light me-3">Voltar</button>

                        <button @click="finalizar" type="button" :class="formularioContato.aceitarTermos ? '' : 'disabled'"
                            class="btn btn-primary">Concluir</button>
                    </div>

                    <div class="col-12 order-md-4 d-flex justify-content-end">
                        <button @click="cancelarReserva" type="button" class="btn btn-light">Cancelar reserva</button>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="getCarregando" class="row">
            <div class="col-12">
                <div class="row d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import NavbarProgressoReserva from "../components/navbarProgressoReserva";
import moment from "moment";
import Captcha from "../components/captcha.vue";
moment.locale("pt-br")
export default {
    name: 'Reserva',
    components: {
        NavbarProgressoReserva,Captcha
    },
    computed: {
        ...mapGetters({
            cars: 'grupoCarro/getGruposCarros',
            getProtectionsCategoria: 'protecao/getProtectionsCategoria',
            getOpcionais: 'protecao/getOpcionais',
            getTotalDiaria: 'reserva/getTotalDiaria',
            getCarregando: 'getCarregando',
            getFormularioReserva: 'reserva/getFormularioReserva',
        }),
    },
    created() {
        document.title = 'King Rent a Car - Reserva'
    },
    mounted() {
        window.scroll({ top: 0 });
        this.searchGruposCarrosReserva();
        this.searchProtections();
        this.searchTariffsOptional();

    },
    updated() {
        window.scroll({ top: 0 });
        if (document.querySelectorAll('[data-bs-toggle="tooltip"]')) {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
            const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        }

    },
    data: () => ({
        progresso: {
            etapa1: false,
            etapa2: false,
            etapa3: false,
            etapa4: false,
        },
        servicos: {
            tipoProtecao: [],
            protecao: [],
            opcionais: []
        },
        tarifa: "LIVRE",
        formularioContato: {
            nome: "",
            cnpjCpf: "",
            email: "",
            codigoPromocional: "",
            telefone: "",
            observacoes: "",
            aceitarTermos: false
        }
    }),

    methods: {
        ...mapMutations({
            atualizarFormularioReserva: 'reserva/atualizarFormularioReserva',
            exibirMaisDetalhes: 'grupoCarro/exibirMaisDetalhes',
            setAlerta: 'setAlerta'
        }),

        ...mapActions({
            searchGruposCarrosReserva: 'grupoCarro/searchGruposCarrosReserva',
            searchProtections: 'protecao/searchProtections',
            searchTariffsOptional: 'protecao/searchTariffsOptional',
            postLoadInfoCart: 'reserva/postLoadInfoCart',
            postFinalize: 'reserva/postFinalize',
        }),

        adicionarRemoverOpcional(id) {
            const jaAdicionado = this.servicos.opcionais.includes(id);
            if (jaAdicionado) {
                this.servicos.opcionais = this.servicos.opcionais.filter(ele => ele != id);
            } else {
                this.servicos.opcionais.push(id);
            }
        },

        adicionarRemoverProtecao(id, tipo) {
            if (tipo == 2) {
                this.servicos.tipoProtecao.shift();
                this.servicos.tipoProtecao.push(id);
            } else {
                const jaAdicionado = this.servicos.protecao.includes(id);
                if (jaAdicionado) {
                    this.servicos.protecao = this.servicos.protecao.filter(ele => ele != id);
                } else {
                    this.servicos.protecao.push(id);
                }
            }
        },

        selecionarCarro(idGrupo) {
            this.progresso.etapa1 = true;
            this.atualizarFormularioReserva({
                tarifarioId: idGrupo
            });
        },

        async selecionarServicos() {
            this.atualizarFormularioReserva({
                protecoesId: [...this.servicos.protecao, ...this.servicos.tipoProtecao],
                opcionaisId: this.servicos.opcionais,
                tarifa: this.tarifa
            })
            await this.postLoadInfoCart();
            this.progresso.etapa2 = true;
        },

        confirmar() {
            this.progresso.etapa3 = true
        },

        cancelarReserva() {
            this.atualizarFormularioReserva(null);
            this.$router.push({ name: "Home" });
        },

        async finalizar() {
            if(this.$refs.captcha.$data.token !== ""){
                this.atualizarFormularioReserva({ ...this.formularioContato });
                await this.postFinalize();
            }else{
                this.setAlerta("naoSouRoboObrigatorio");
            }
        },

        formatarData(value) {
            return moment(value).format("L")
        },

        formatarValor(value) {
            const valorFormatado = Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            return valorFormatado
        }
    }
}
</script>

<style scoped>
.hide {
    display: none;
}

.card-carro {
    min-height: 450px;
}

.img-fluid-carro {
    max-height: 134px;
}

.bg-grey {
    background-color: #d9d8da47 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.card-footer {
    background-color: transparent;
    border-top: none;
}

.small {
    font-size: .75em;
}

.small-2 {
    font-size: .5em;
}
</style>