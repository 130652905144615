<template>
    <div class="container mt-5 text-justify">
        <div class="col-12 col-md-12 col-lg-12">
            <p>
                A decisão de escolher entre alugar um carro em uma ou outra locadora certamente envolve a consideração do
                preço do aluguel.
            </p>
            <p>
                Mas quem já teve algum tipo de problema ao alugar um veículo sabe que o preço é apenas mais um fator que
                deve ser levado em conta na decisão de qual locadora escolher. Na KING RENT A CAR, além de encontrar preços
                bastante competitivos, você terá a segurança de estar alugando um carro com uma empresa reconhecida no
                Sudeste pelos serviços de qualidade prestados há mais 42 anos.
            </p>
            <p>
                Não é por acaso que a KING RENT A CAR possui um índice de satisfação de clientes acima de 90%. Certificada
                pela norma NBR ISO 9001:2015, a KING sempre buscou não só a satisfação dos seus clientes, mas também superar
                suas expectativas. A nossa equipe é treinada para propiciar tranquilidade e conforto para quem aluga o
                veículo. A frota da empresa possui veículos dos mais variados fabricantes e modelos, e a sua renovação é
                constante. Na KING você não tem problemas com carros velhos e desgastados.
            </p>
            <p>
                Com o intuito de proporcionar ainda mais conforto, oferecemos a opção, de clientes hospedados em hotéis, o
                serviço de leva e traz. Outra opção de serviço é a entrega de veículos nas empresas. Todos esses
                diferenciais citados são de grande importância para os nossos clientes. Mas o diferencial de que a KING mais
                se orgulha é o de poder personalizar o serviço de acordo com cada cliente. A nossa missão não é alugar um
                carro, mas sim disponibilizar soluções para os nossos clientes.
            </p>
            <p>
                Portanto, você tem vários motivos para alugar um carro com a KING. Entre em contato com nosso time comercial
                e se certifique das condições e disponibilidades.
            </p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Diferenciais',
    components: {},
    methods: {},
    computed: {},
    mounted() {
        window.scroll({ top: 0 })
    },
    created(){
        document.title = 'King Rent a Car - Diferenciais'
    },
}
</script>