<template>
    <div class="img-fluid" id="banner">
        <div class="container">
            <div class="row my-md-5">
                <div class="col py-5">
                    <h2 class="text-white text-end">
                        <b>{{ $router.currentRoute.value.name }}</b>
                    </h2>
                    <div class="text-white">
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "banner",
}
</script>

<style scoped>
#banner {
    background-image: url("https://www.kingcar.com.br/img/cabec1.jpg");
    width: 100%;
    background-size: cover;
    background-position-x: center;
    position: relative;
    display: inline-block;
}

hr {
    opacity: 0.4;
}
</style>