<template>
    <div class="container mt-5 text-justify">
        <div class="col-12 col-md-12 col-lg-12">
            <b>O que é o serviço de terceirização de frota?</b>

            <p>A terceirização de frota tem se tornado prática comum nas empresas brasileiras, impulsionada principalmente
                pela busca de competitividade, qualidade, redução de custos, benefícios fiscais e pela preocupação dos
                empresários em concentrar esforços no verdadeiro foco de seus negócios. </p>

            <p>A KING oferece um total suporte para que as empresas possam usufruir de todos os benefícios da terceirização
                sem que para isso tenha que despender muitos recursos humanos e financeiros. Dessa forma, você não vai mais
                precisar se preocupar com o veículo que está ficando antigo, custos de manutenção, achar um mecânico de
                confiança, além de não sofrer a depreciação do próprio bem. </p>
                
            <b>Como Funciona?  </b>

            <p>Você vai entrar em contato com a KING para que nossos consultores conheçam a sua empresa e seus principais
                problemas com frota. O próximo passo é elaborar um projeto de frota exclusivo para atender às necessidades
                da sua empresa dentro da disponibilidade de verba. Nesse planejamento você vai escolher a categoria de
                carros que deseja. </p>

            <p>Caso ocorra algum problema com os veículos, basta entrar em contato com nossa equipe que prontamente irá lhe
                atender para solucionar o mais rápido possível. </p>


            <b>Quais são as vantagens de terceirizar sua frota? </b>


            <p class="m-0">• Eliminação de altos investimentos a curto prazo; </p>

            <p class="m-0">• Eliminação da depreciação; </p>

            <p class="m-0">• Serviços de emplacamento/licenciamento por conta da KING; </p>

            <p class="m-0">• Melhor adequação entre custo, utilização e fluxo de caixa; </p>

            <p class="m-0">• Abatimento integral no Imposto de Renda; </p>

            <p class="m-0">• Não vai precisar pagar IPVA, Seguro; </p>

            <p class="m-0">• Não paga reposição de peças como pneus, amortecedores etc.; </p>

            <p class="m-0">• Adequação constante da quantidade, tipos, marcas e modelos dos veículos, inclusive em períodos
                determinados (corpo de vendas, acompanhamento de obras etc); </p>

            <p class="m-0">• Revisões constantes e, quando há necessidade de manutenção, a substituição é garantida; </p>

            <p class="m-0">• Fornecimento de carro reserva em caso de acidente ou manutenção; </p>

            <p class="m-0">• Gerenciamento de multas de trânsito; </p>

            <p class="m-0">• Contratação de seguros contra roubo ou furto, incêndio, acidentes; </p>

            <p class="m-0">• Controle integral dos custos e despesas, com documentos contábeis adequados ao controle de sua
                empresa; </p>

            <p class="m-0">• Eliminação de ociosidade e atendimento mais eficiente quanto ao possível aumento de demanda;
            </p>

            <p class="m-0">• Despreocupação com sinistro ou substituição de veículos usados; </p>

            <p class="m-0">• Assistência 24h em casos de pane ou acidente; </p>

            <p class="">• Manutenções preventivas e corretivas; </p>


            <b>Conheça as unidades da King perto de você e agende uma visita. </b>

            <p class="m-0">• Vitória/ES </p>

            <p class="m-0">• Aracruz/ES </p>

            <p class="m-0">• São Paulo/SP </p>

            <p class="">• Belo Horizonte/MG </p>

            <p>Esses são alguns dos principais motivos para você terceirizar sua frota. Agora quer saber os motivos para
                você terceirizar com a KING? Agende uma visita com um de nossos consultores. Nossas soluções te
                surpreenderão! </p>

            <p>Para mais informações entre em contato através do telefone 0800 702 1711 </p>
        </div>
</div></template>

<script>

export default {
    name: 'Terceirização',
    components: {},
    mounted() {
        window.scroll({ top: 0 })
    },
    created(){
        document.title = 'King Rent a Car - Terceirização'
    },
}
</script>