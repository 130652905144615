<template>
    <div class="row">
        <div class="col-12 py-5">
            <div class="container">
                <div class="row">
                    <h4 class="text-center mb-5">Dúvidas ou Sugestões? Fale Conosco</h4>
                </div>
                <div class="row d-flex justify-content-center">
                    <div class="col-md-6">
                        <form class="" v-on:submit.prevent="validar">

                            <div class="form-floating mb-3">
                                <input required v-model="formularioContato.nome" type="text" class="form-control" id="nome">
                                <label for="nome">Nome</label>
                            </div>

                            <div class="form-floating mb-3">
                                <input required v-model="formularioContato.cidade" type="text" class="form-control" id="cidade"
                                >
                                <label for="cidade">Sua cidade</label>
                            </div>

                            <div class="form-floating mb-3">
                                <input required v-model="formularioContato.ondeGostariaAlugar" type="text" class="form-control"
                                    id="ondeGostariaAlugar">
                                <label for="ondeGostariaAlugar">Onde gostaria de alugar?</label>
                            </div>

                            <div class="form-floating mb-3">
                                <input required v-model="formularioContato.telefone" type="tel" class="form-control" id="telefone"
                                    v-mask="['(##) ####-####', '(##) #####-####']">
                                <label for="telefone">Seus telefones de contato</label>
                            </div>

                            <div class="form-floating mb-3">
                                <input required v-model="formularioContato.email" type="email" class="form-control" id="email"
                                >
                                <label for="email">Informe seu email</label>
                            </div>

                            <div class="form-floating mb-3">
                                <textarea required v-model="formularioContato.mensagem" class="form-control" id="mensagem" rows="4"
                                    style="height: auto;"></textarea>
                                <label for="mensagem">Mensagem</label>
                            </div>

                            <div class="mb-3">
                                 <Captcha ref="captcha"></Captcha>
                            </div>
                            
                            <div class="d-grid gap-2">
                                <button v-if="getCarregando" class="btn btn-primary" disabled>
                                    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                </button>
                                <button v-else type="submit" class="btn btn-primary">Enviar</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import Captcha from "./captcha.vue";

export default {
    name: "contato",
    components: {
        Captcha
    },
    computed: {
        ...mapGetters({
            getCarregando: 'getCarregando',
        })
    },
    data() {
        return {
            formularioContato: {
                nome: "",
                cidade: "",
                ondeGostariaAlugar: "",
                telefone: "",
                email: "",
                mensagem: "",
            },
        }
    },
    methods: {
        ...mapActions({
            postContactsSendEmail: 'empresa/postContactsSendEmail',
        }),
        ...mapMutations({
            setAlerta: 'setAlerta'
        }),
        async validar() {
            if(this.$refs.captcha.$data.token !== ""){
                this.postContactsSendEmail(this.formularioContato);
            }else{
                this.setAlerta("naoSouRoboObrigatorio");
            }
        },
    },
}
</script>