<template>
    <div class="container mt-5">
        <p>• Ter idade mínima de 21 anos. </p>
        <p>• Condutor habilitado no mínimo há 2 anos. </p>
        <p>• Necessário apresentação da CNH original, ou a versão digital, e não possuir restrições de qualquer espécie,
            inclusive Denatran, SPC e Serasa. </p>
        <p>• Para estrangeiros necessário apresentar Passaporte e Carteira de Habilitação originais e válidos e visto dentro
            do prazo permitido para permanência no Brasil; </p>
        <p>• Necessário possuir cartão de crédito com limite para a pré-autorização. </p>
        <p>• Aceitamos os cartões Visa, Mastercard, Banescard, Amex, Elo e Picpay. </p>
        <p>• Não aceitamos cartões que não sejam de instituições bancárias, como de lojas Pernambucanas e etc. </p>
        <p>• Parcelamos a locação em até 5x sem juros no cartão de crédito. </p>
        <p>• Faturamos para empresas mediante cadastro prévio. </p>
        <p>• A diária do carro é de 24 horas, com até 1 hora de tolerância para devolução, sem custo adicional, à exceção da
            proteção. </p>
        <p>• A hora extra do veículo corresponde a 1/6 da diária. </p>
        <p>• O carro é entregue com o tanque cheio e deverá ser devolvido nas mesmas condições. Caso contrário o
            reabastecimento é cobrado com base na tabela da KING. </p>
        <p>• As locações poderão ser atendidas com o carro do mesmo grupo ou de grupo superior, na falta de veículo do grupo
            reservado. </p>
        <p>• Em caso de colisão, roubo, furto, incêndio ou perda total, é necessário a apresentação do Boletim de Ocorrência
            e o preenchimento do relatório de sinistro junto à locadora. </p>
        <p>• Taxa de serviço - 10% </p>
        <p>• Descontos promocionais não são cumulativos. </p>
    </div>
</template>

<script>

export default {
    name: "comoAlugar",
    components: {},
    methods: {},
    computed: {},
    mounted() {
        window.scroll({ top: 0 });
    },
    created(){
        document.title = 'King Rent a Car - Como Alugar'
    },
}
</script>