<template>
    <div class="row bg-grey">
        <div class="col-12 py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 text-center py-sm-3 my-3 me-md-5 bg-primary d-flex justify-content-center align-items-center">
                        <div class="text-center">
                            <h3><i class="bi bi-geo-alt-fill text-white"></i></h3>
                        <h3 class="text-white">Lojas</h3>
                        </div>
                    </div>

                    <div v-for="(loja, key) in lojas" :key="key" class="col text-center my-3">
                        <p class="text-primary m-0">{{ formatarEstado(loja.estado) }}</p>
                        <p class="text-primary fw-bold">{{ loja.titulo }}</p>
                        <p class="my-3">
                            <small class="lh-1">
                                {{ loja.rua }}, {{ loja.numero }}
                                {{ loja.bairro }} – {{ loja.cidade }} - {{ loja.estado }}
                            </small>
                        </p>
                        <p class="my-3">
                            <p class="lh-1 small mb-1" v-for="hora in horarioFuncionamento(loja)">
                                {{ hora }}
                            </p>
                        </p>
                        <p class="my-3">
                            <small class="lh-1">
                                {{ loja.telefone }}
                                {{ loja.email }}
                            </small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale('pt-br')
export default {
    name: "lojas",
    components: {
    },
    computed: {
        ...mapGetters({
            lojas: 'lojas/getLojas',
        })
    },
    methods: { 
        formatarEstado(sigla){
            switch (sigla) {
                case "ES":
                    return "Espírito Santo";

                case "MG":
                    return "Minas Gerais";

                case "SP":
                    return "São Paulo";
            
                default:
                    return sigla
            }
        },
        horarioFuncionamento(loja){
            const abreSegunda = loja.abreSegunda.slice(0, 5);
            const fechaSexta = loja.fechaSexta.slice(0, 5);
            const abreSabado = loja.abreSabado.slice(0, 5);
            const fechaSabado = loja.fechaSabado.slice(0, 5);
            const abreDomingo = loja.abreDomingo.slice(0, 5);
            const fechaFeriado = loja.fechaFeriado.slice(0, 5);
            const horarioFuncionamento = [`Seg. a Sexta - ${abreSegunda}h às ${fechaSexta}h`];
            if(loja.abreSabado != "00:00:00"){
                horarioFuncionamento.push(`Sábados - ${abreSabado}h às ${fechaSabado}h`);
            }
            if(loja.abreDomingo != "00:00:00"){
                horarioFuncionamento.push(`Domingo e feriado - ${abreDomingo}h às ${fechaFeriado}h`);
            }
            return horarioFuncionamento;
        }
    }
}
</script>

<style scoped>
small, .small{
    font-weight: 600;
}
</style>