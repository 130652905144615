<template>
  <vue-recaptcha ref="recaptcha" sitekey="6LcjyH8oAAAAAIVzxWUl6RAxI6bVC4uDbNFg_3tc" @verify="verifyMethod"/>
</template>

<script>
  import { VueRecaptcha } from 'vue-recaptcha';
  export default {
    components: { VueRecaptcha },

    data(){
      return {
        token: ""
      }
    },
    methods: {
      onEvent() {
        // when you need a reCAPTCHA challenge
        this.$refs.recaptcha.execute();
      },
      verifyMethod(response){
        this.token = response;
        setTimeout(() => {
          this.token = ""
        }, 60000);
      }
    }
  };
</script>