import service from "./service";

export const comoAlugar = {
    namespaced: true,
    state: () => ({
        rents: [],
        rent: {}
    }),
    mutations: {
        setRents(state, data) {
            state.rents = data;
        },
        setRent(state, data) {
            state.rent = data;
        }
    },
    actions: {
        async searchRents({ state, commit, rootState }) {
            commit('setCarregando', true, { root: true });
            try {
                const response = await service.searchRents();
                const { items } = await response.data;
                if (items.length > 0) {
                    commit('setRents', items)
                    commit('setRent', items[0])
                }
                commit('setCarregando', false, { root: true });
            } catch (error) {
                commit('setCarregando', false, { root: true });
                commit('setAlerta', "erroPadrao", { root: true });
            }
        }
    },
    getters: {
        getRents(state, getters, rootState) {
            return state.rents
        },
        getRent(state, getters, rootState) {
            return state.rent
        }
    }
}