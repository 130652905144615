<template>
    <div id="carouselExampleDark" class="carousel slide d-flex justify-content-center">
        <div class="carousel-indicators">
            <button v-for="(item, key) in banners" type="button" data-bs-target="#carouselExampleDark" :data-bs-slide-to="key" :class="key === 0 ? 'active' : ''" aria-current="true" :aria-label="`Slide ${key}`"></button>
        </div>
        
        <div class="carousel-inner">
            <div v-for="(item, key) in banners" :key="key" :class="key === 0 ? 'active' : ''" class="carousel-item">
                <a v-if="item.url !== ''" :href="item.url">
                    <img class="d-block float-end img-fluid"
                    :src='`https://www.kingcar.com.br/uploads/banners/${item.imagem}`'>
                </a>
                
                <img v-else class="d-block float-end img-fluid"
                    :src='`https://www.kingcar.com.br/uploads/banners/${item.imagem}`'>
            </div>
        </div>
        <button class="btn btn-tertiary prev rounded-circle d-flex justify-content-center align-items-center" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
            <i class="bi bi-chevron-left"></i>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="btn btn-tertiary next rounded-circle d-flex justify-content-center align-items-center" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
            <i class="bi bi-chevron-right"></i>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "carrosel",
    computed: {
        ...mapGetters({
            banners: 'empresa/getBanners',
        })
    },
    mounted() {
        window.scroll({ top: 0 });
        this.searchBannersCarrousel();
    },
    data: () => ({

    }),
    methods: {
        ...mapActions({
            searchBannersCarrousel: 'empresa/searchBannersCarrousel',
        }),
    }
}
</script>

<style scoped>
.next {
    right: 1%;
}

.prev {
    left: 1%;
}

.next, .prev {
    position: absolute;
    top: 50%;
    z-index: 1;
    text-align: center;
    transition: opacity .15s ease;
    width: 30px;
    height: 30px;
    font-size: 15px;
}

.w-70{
    width: 70%;
}

.w-50{
    width: 50%;
}
</style>