<template>
    <div class="container mt-5 text-justify">
        <div class="clearfix mb-3">
            <!-- <div class="w-30 float-lg-start me-4 mb-2">
                <img class="img-thumbnail img-fluid" :src="`https://www.kingcar.com.br/uploads/fotosdaloja/${company.imagem}`">
            </div> -->
            <p class="mb-4">
                A KING Automotores foi fundada há 42 anos, instalando-se em frente ao Aeroporto de Vitória
                - ES, para suprir a demanda capixaba em serviços de locação de veículos. 
            </p>

            <p class="mb-4">
                Sempre atuando de maneira a se tornar uma referência no mercado, desde 1981 é destaque pela
                excelência no atendimento ao cliente, flexibilidade, qualidade dos
                veículos oferecidos, administração eficiente e, é claro, preços e condições de pagamento
                diferenciados.
            </p>

            <p class="mb-4">
                Uma empresa capixaba que vislumbrou a oportunidade de fechar negócios em locais que apresentavam crescimento
                industrial e de serviços, com demanda satisfatória para locação de veículos. A primeira das conquistas foi a
                cidade de Aracruz - ES, onde a KING abriu sua primeira filial, com a finalidade inicial
                para atender à Aracruz Celulose e suas empresas terceirizadas. Depois, no contexto de melhoria contínua do
                atendimento, instalou o balcão KING no Aeroporto de Vitória. Mais tarde, veio a loja de Belo Horizonte, cujo
                potencial de negócios é extremamente atraente. Por fim, a chegada ao maior centro empresarial da América
                latina, São Paulo, onde a empresa se faz presente desde 2003.
            </p>

            <p class="mb-4">
                A busca pela excelência nos serviços sempre foi o grande objetivo da KING. É por isso que em 2008, a KING
                certificou o seu sistema de gestão em conformidade com a norma NBR ISO 9001:2008. Tal certificação ajuda a
                desenvolver cada vez mais o nosso trabalho no dia a dia e também traz segurança e tranquilidade para os
                nossos clientes. Em 2016, a KING foi uma das primeiras empresas do Espírito Santo a atualizar sua
                certificação para a NBR ISO 9001:2015, demonstrando o compromisso em se manter sempre atualizada na busca da
                melhoria contínua.
            </p>
        </div>
        <div class="row">
            <div class="col-md-12">
                <b style=" display: inline-block; color: #d40e1e; margin-bottom: 10px; text-transform: uppercase;">Nosso
                    negócio</b>
                <p>Solução em aluguel de veículos</p>
            </div>

            <div class="col-md-12 my-4">
                <b style=" display: inline-block; color: #d40e1e; margin-bottom: 10px; text-transform: uppercase;">Nossos
                    valores</b>
                <div class="row">
                    <div class="col-md-6">
                        <p class="mb-1"><strong>Ética:</strong> Respeito às pessoas e a organização.</p>
                        <p class="mb-1"><strong>Segurança e qualidade:</strong> Garantia da segurança das pessoas e da
                            qualidade dos nossos serviços.</p>
                        <p class="mb-1"><strong>Satisfação:</strong> Comprometimento com a satisfação dos clientes,
                            fornecedores e colaboradores.</p>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-1"><strong>Excelência:</strong> Busca contínua pelo melhor desempenho.</p>
                        
                        <p class="mb-1"><strong>Integração e superação:</strong> Colaboração entre as pessoas para superar
                            expectativas.</p>
                        <p class="mb-1"><strong>Resultado:</strong> Geração de resultados positivos. </p>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <b style="display: inline-block; color: #d40e1e; margin-bottom: 10px; text-transform: uppercase;">Missão</b>
                <p>Oferecer soluções em aluguel de veículos com qualidade, segurança e flexibilidade, garantindo a
                    satisfação dos clientes e desenvolvendo nossos colaboradores. </p>

            </div>
            <div class="col-md-6">
                <b style=" display: inline-block; color: #d40e1e; margin-bottom: 10px; text-transform: uppercase;">Visão</b>
                <p> Ser reconhecida como a empresa de melhor custo-benefício em aluguel de veículos na região sudeste, com
                    crescimento sustentável.</p>
            </div>



            <div class="col-12 col-md-12 col-lg-12 mt-4">
                <div class="row fotos-loja">
                    <div class="col-4" v-for="photo in photos" :key="photo.id">
                        <img :src="`https://www.kingcar.com.br/uploads/fotosdaloja/${photo.imagem}`"
                            class="img-responsive img-thumbnail">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Institucional',
    components: {},
    methods: {
        ...mapActions({
            searchCompanyPhotos: 'empresa/searchCompanyPhotos',
        }),
    },
    computed: {
        ...mapGetters({
            photos: 'empresa/getPhotos',
        })
    },
    mounted() {
        this.searchCompanyPhotos()
    },
    created(){
        document.title = 'King Rent a Car - Institucional'
    },
}
</script>

<style>
.w-30 {
    width: 30%;
}

strong {
    color: #d40e1e;
}
</style>