<template>
    <div class="d-flex justify-content-evenly ">
        <div class="w-25 d-flex align-items-center">
            <hr class="w-100 border-primary">
            <h6 class="mx-3 small"><span :class="progresso.etapa1 ? 'bg-primary' : 'border border-primary'" class=" rounded-circle px-2 py-1 ">1</span></h6>
        </div>

        <div class="w-25 d-flex align-items-center">
            <hr class="w-100" :class="progresso.etapa1 ? 'border-primary' : ''">
            <h6 class="mx-3 small">
                <span :class="[progresso.etapa1 && !progresso.etapa2 ? 'border border-primary' : '', progresso.etapa2 ? 'bg-primary' : 'border border-dark-subtle']" class="rounded-circle px-2 py-1 ">
                    2
                </span>
            </h6>
        </div>

        <div class="w-25 d-flex align-items-center">
            <hr class="w-100" :class="progresso.etapa2 ? 'border-primary' : ''">
            <h6 class="mx-3 small">
                <span :class="[progresso.etapa2 && !progresso.etapa3 ? 'border border-primary' : '', progresso.etapa3 ? 'bg-primary' : 'border border-dark-subtle']" class=" rounded-circle px-2 py-1 ">
                    3
                </span>
            </h6>
        </div>

        <div class="w-25 d-flex align-items-center">
            <hr class="w-100" :class="progresso.etapa3 ? 'border-primary' : ''">
            <h6 class="mx-3 small">
                <span :class="[progresso.etapa3 && !progresso.etapa4 ? 'border border-primary' : '', progresso.etapa4 ? 'bg-primary' : 'border border-dark-subtle']" class=" rounded-circle px-2 py-1 ">
                    4
                </span>
            </h6>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "navbarProgressoReserva",
    props: {
        progresso: {
            etapa1: Boolean,
            etapa2: Boolean,
            etapa3: Boolean,
            etapa4: Boolean,
        },

    }
}
</script>

<style scoped>
.progress,
.progress-stacked {
    background-color: #c00d1c;
}

.progress-bar {
    background-color: #c00d1c;
}

.border-primary {
    border-color: #c00d1c!important;
}
</style>