<template>
    <nav class="navbar navbar-expand-lg sticky-top bg-white shadow-sm">
        <div class="container">
            <routerLink to="/" class="navbar-brand">
                <img class="" id="img-navbar" src="./../assets/images/logo3.png" alt="">
            </routerLink>

            <button id="botaoNavBar" class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <i class="bi bi-justify"></i>
            </button>

            <div class="collapse navbar-collapse me-3" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-uppercase fs-7">
                    <li class="nav-item" v-on:click="fecharCollapse">
                        <routerLink to="/" class="nav-link">
                            Home
                        </routerLink>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            A empresa
                        </a>
                        <ul class="dropdown-menu">
                            <li v-on:click="fecharCollapse"><router-link to="institucional" class="dropdown-item">Institucional</router-link></li>
                            <li v-on:click="fecharCollapse"><router-link to="diferenciais" class="dropdown-item">Diferenciais</router-link></li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Aluguel
                        </a>
                        <ul class="dropdown-menu">
                            <li v-on:click="fecharCollapse"><router-link to="/grupo-de-carros" class="dropdown-item">Grupo de Carros</router-link></li>
                            <li v-on:click="fecharCollapse"><router-link to="/protecao" class="dropdown-item">Proteção</router-link></li>
                            <li v-on:click="fecharCollapse"><router-link to="/como-alugar" class="dropdown-item" href="#">Como Alugar</router-link></li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Para Empresas
                        </a>
                        <ul class="dropdown-menu">
                            <li v-on:click="fecharCollapse"><router-link to="/terceirizacao" class="dropdown-item">Terceirização</router-link></li>
                            <li v-on:click="fecharCollapse"><router-link to="/rac" class="dropdown-item">RAC</router-link></li>
                        </ul>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            Contato
                        </a>
                        <ul class="dropdown-menu">
                            <li v-on:click="fecharCollapse"><a class="dropdown-item" href="#lojas">Lojas</a></li>
                            <li v-on:click="fecharCollapse"><a class="dropdown-item" href="#contato">Contatos</a></li>
                        </ul>
                    </li>

                </ul>
                <div id="listaRedesSociais" class="d-flex justify-content-evenly order-md-2">
                    <div class="mx-4">
                        <a :href="facebook" target="_blank">
                            <img class="img-redes-sociais" src="./../assets/icons/icon-facebook.svg" alt="">
                        </a>
                    </div>

                    <div class="mx-4">
                        <a :href="instagram" target="_blank">
                            <img class="img-redes-sociais" src="./../assets/icons/icon-instagram.svg" alt="">
                        </a>
                    </div>

                    <div class="mx-4">
                        <a :href="twitter" target="_blank">
                            <img class="img-redes-sociais" src="./../assets/icons/icon-twitter.svg" alt="">
                        </a>
                    </div>

                    <div class="mx-4">
                        <a :href="whatsapp" target="_blank">
                            <img class="img-redes-sociais" src="./../assets/icons/icon-whatsapp.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>

        </div>

    </nav>
</template>

<script>
import { linksRedesSociais } from "./../assets/constantes";

export default {
    name: "navbar",
    data() {
        return {
            facebook: linksRedesSociais.facebook,
            instagram: linksRedesSociais.instagram,
            twitter: linksRedesSociais.twitter,
            whatsapp: linksRedesSociais.whatsapp,
        };
    },
    methods: {
        fecharCollapse() {
            document.getElementById("botaoNavBar").click()

        }
    }
}
</script>

<style scoped>
#img-navbar {
    width: 120px;
}

.img-redes-sociais {
    width: 25px;
}

.list-group-item {
    background-color: transparent !important;
    border: none !important;
}

.nav-link {
    margin: 10px 10px 10px 10px;
}

.navbar-toggler {
    background-color: #d40e1e;
    padding: 2px 5px !important;
    border: #d40e1e;
    color: white;
    font-size: 1.5rem;
}

.bg-white {
    background-color: rgba(255, 255, 255, 0.7) !important;
}
</style>