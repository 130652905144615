<template>
    <div class="my-5 img-fluid bgc-dark-candy-apple-red" id="mapaBanner">
        <div class="col-12 py-5">
            <div class="container">
                <div class="row d-flex justify-content-between">
                    <div class="col-md-6 text-white opacity-85">
                        <div class="py-3">
                            <h3 class="text-white"> <i class="text-white bi bi-whatsapp me-3"></i> {{ getTelefones.principal }}</h3>
                        </div>
                        <hr>
                        
                        <div class="py-3">
                            <h3 class="text-white"> <i class="text-white bi bi-telephone-fill me-3"></i> {{ getTelefones.fixo }}</h3>
                        </div>
                        <hr>

                        <div class="py-3">
                            <img src="../assets/icons/guincho.png" class="w-25 mb-3">
                            <h3 class="text-white">{{ getTelefones.seguro }}</h3>
                        </div>
                        <hr>

                        <div class="py-3">
                            <h5 class="text-white">reserva@kingcar.com.br</h5>
                            <img src="../assets/images/logo3.png" class="w-15">
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-5">
                        <div class="img-thumbnail">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7486.135682208551!2d-40.290414!3d-20.256021!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xb81868555d8143%3A0x5b66952fc67dd7a2!2sKing%20rent%20a%20car%20-%20Aluguel%20de%20carros!5e0!3m2!1spt-BR!2sus!4v1688857555364!5m2!1spt-BR!2sus"
                                width="100%" height="300px" style="border:0;" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            getTelefones: 'empresa/getTelefones',
        })
    },
}
</script>

<style scoped>
#mapaBanner {
    background-image: url("../assets/images/city-overlay.png");
    width: 100%;
    clear: both;
    background-position: 50% 50%;
    padding: 0 50px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    display: inline-block;
    background-attachment: fixed;
}

hr {
    opacity: 0.4;
}

.bgc-dark-candy-apple-red {
    background-color: #9F090B;
}

.img-thumbnail {
    padding: .75rem;
}
</style>